import { memo } from 'react';
import styled from '@emotion/styled';
import { usePerfStore_deprecated } from '~/modules/SDK/Perf/usePerfStore';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { createDesktopCss } from '~/css/createDesktopCss';
import { css } from '@emotion/react';
export const PerfDataInfo1 = memo(function PerfDataInfo(props) {
    const winPerTrade = usePerfStore_deprecated(state => state.summary?.profitWinPerTrade ?? 0);
    const lossPerTrade = usePerfStore_deprecated(state => state.summary?.profitLossPerTrade ?? 0);
    const Totaltrade = usePerfStore_deprecated(state => state.summary?.tradesSize ?? 0);
    const winTradeCount = usePerfStore_deprecated(state => state.summary?.winTradeCount ?? 0);
    const lossTradeCount = Totaltrade - winTradeCount;
    const winRate = usePerfStore_deprecated(state => state.summary?.winRate ?? 0);
    const profitTotalTrade = usePerfStore_deprecated(state => state.summary?.profitTotalTrade ?? 0);
    /** 交易次數 */
    const tradeCount = Number(Totaltrade).toFixed(0);
    /** 累積獲利 */
    const accProfit = Number(profitTotalTrade).toFixed(0);
    /** 賺賠比 */
    const tradeRatio = Number(winPerTrade / -lossPerTrade).toFixed(2);
    /** 獲利次數 */
    const winCount = Number(winTradeCount).toFixed(0);
    /** 平均獲利 */
    const avgWinProfit = Number(winPerTrade).toFixed(0);
    /** 平均虧損 */
    const avgLossProfit = Number(lossPerTrade).toFixed(0);
    /** 虧損次數 */
    const lossCount = Number(lossTradeCount).toFixed(0);
    /** 勝率 */
    const winRatio = Number(winRate).toFixed(2);
    /** 勝率 */
    const profitFactor = Number(usePerfStore_deprecated(state => state.summary?.profitFactor ?? 0) ?? 0).toFixed(2);
    const DataItem = function DataItem(props_) {
        return (<PerDataItem>
        <PerDataTitle>{props_.title}</PerDataTitle>
        <PerDataText>
          {props_.data} {props_.unit}
        </PerDataText>
      </PerDataItem>);
    };
    return (<PerDataInfoBox>
      <DataItem title={'交易次數'} data={tradeCount}/>
      <DataItem title={'累積獲利'} data={accProfit}/>
      <DataItem title={'勝率'} data={winRatio} unit={'%'}/>
      <DataItem title={'平均獲利'} data={avgWinProfit}/>
      <DataItem title={'平均虧損'} data={avgLossProfit}/>
      <DataItem title={'賺賠比'} data={tradeRatio}/>
      <DataItem title={'獲利次數'} data={winCount}/>
      <DataItem title={'虧損次數'} data={lossCount}/>
      <DataItem title={'獲利因子'} data={profitFactor}/>
    </PerDataInfoBox>);
});
export const PerfDataInfo2 = memo(function PerfDataInfo(props) {
    /** 最大獲利 */
    const maxWin = Number(usePerfStore_deprecated(state => state.summary?.maxWinTrade) ?? 0).toFixed(0);
    /** 最大虧損 */
    const maxLoss = Number(usePerfStore_deprecated(state => state.summary?.maxLossTrade) ?? 0).toFixed(0);
    /** 風險報酬比 */
    const riskRatio = Number(usePerfStore_deprecated(state => state.summary?.riskRatio) ?? 0).toFixed(2);
    /** 策略最大回撤 */
    const mdd = Number(usePerfStore_deprecated(state => state.summary?.maxDrawdown) ?? 0);
    const DataItem = function DataItem(props_) {
        return (<PerDataItem>
        <PerDataTitle>{props_.title}</PerDataTitle>
        <PerDataText>
          {props_.data} {props_.unit}
        </PerDataText>
      </PerDataItem>);
    };
    return (<PerDataInfoBox>
      <DataItem title={'最大獲利'} data={maxWin}/>
      <DataItem title={'最大虧損'} data={maxLoss}/>
      <DataItem title={'風報比'} data={riskRatio}/>
      <DataItem title={'最大回撤'} data={mdd}/>
      <DataItem title={''} data={''}/>
      <DataItem title={''} data={''}/>
      <DataItem title={''} data={''}/>
      <DataItem title={''} data={''}/>
      <DataItem title={''} data={''}/>
    </PerDataInfoBox>);
});
export const PerfDataInfoGrid = memo(function PerfDataInfo(props) {
    /** 策略起始日 */
    const startTradeDate = usePerfStore_deprecated(state => state.summary?.startDate || '');
    /** 策略最新日 */
    const endTradeDate = usePerfStore_deprecated(state => state.summary?.endDate || '');
    return (<InfoBlockContainer>
      <InfoDateBlock>
        <div>策略回測範圍 : </div>
        &nbsp;{startTradeDate} - {endTradeDate}
      </InfoDateBlock>
      <InfoBlock>
        <PerfDataInfo1 />
        <PerfDataInfo2 />
      </InfoBlock>
    </InfoBlockContainer>);
});
export const InfoBlockContainer = styled.div `
  ${flex.v.default};
  width: 100%;
  height: 100%;
  margin: 0px 0px 0px 2.5%;
  ${createDesktopCss(css `
    width: 50%;
  `)}
`;
export const PerDataInfoBox = styled.div `
  ${flex.v.default};
  width: 100%;
  height: 100%;
`;
export const InfoDateBlock = styled.div `
  ${flex.h.crossCenter};
  width: 100%;
  height: 10%;
  margin: 0px 0px 0px 5px;
`;
export const InfoBlock = styled.div `
  ${flex.h.default};
  width: 100%;
  height: 90%;
`;
export const PerDataItem = styled.div `
  ${flex.h.default};
  width: 100%;
  height: 8%;
  margin: 3px 0px 3px 0px;
`;
export const PerDataTitle = styled.div `
  ${flex.h.allCenter};
  background-color: #e9e9e9;
  margin: 0px 5px 0px 5px;
  width: 50%;
  height: 100%;
`;
export const PerDataText = styled.div `
  ${flex.h.crossCenter};
  margin: 0px 5px 0px 5px;
  background-color: #ffffff;
  width: 50%;
  height: 100%;
`;
